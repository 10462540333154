import React, { useEffect, useState } from "react"
import queryString from "query-string"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Seo from "../components/seo"
import Moment from 'react-moment';

const DraftPage = ({ location }) => {
  const { contentId, draftKey } = queryString.parse(location.search);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(`https://lifefix.microcms.io/api/v1/portal-news/${contentId}?draftKey=${draftKey}`, {
      headers: {
        'X-MICROCMS-API-KEY': "fb939c1a-996a-416b-98b4-85a697b5bf4f",
      },
    })
      .then(res => res.json())
      .then(res => setData({ microcmsPortalNews: res }));  // ※1
  }, []);  // ※2

  if (data === null) {
    return null;
  }

  return (
    <Layout>
      <Seo
        title={data.microcmsPortalNews.title}
        description={data.microcmsPortalNews.abstract}
      />

      <section className="bg-base-main px-8 pt-16">
        <div className="max-w-5xl mx-auto">

          <div className="flex flex-wrap">
            <div className="">
              <Link to={`/`}>
                <button className="text-secondry-main text-sm underline hover:no-underline">TOP</button>
              </Link>
              <span className="mx-4 text-sm">></span>
              <Link to={`/news/1`}>
                <button className="hover:text-base-dark text-base-dark text-sm underline hover:no-underline">イベント情報・お知らせ</button>
              </Link>
              <span className="mx-4 text-sm">></span>
              <Link to={`/news-detail/${data.microcmsPortalNews.portalNewsId}`}>
                <button className="hover:text-base-dark text-base-dark text-sm underline hover:no-underline">{data.microcmsPortalNews.title}</button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-base-main px-8 py-4">
        <div className="container max-w-5xl mx-auto">

          <div className="py-6 px-4 border-2 border-base-dark bg-white rounded-lg">
            <div className="">
              <div className="py-4">
                <h1 className="font-bold text-lg">
                  {data.microcmsPortalNews.title}
                </h1>
              </div>
              <div className="text-md text-base-dark w-full flex justify-between">
                <span className="bg-base-dark rounded-full font-bold text-md text-white px-4 py-1">
                  <span className="text-xs">{data.microcmsPortalNews.category}</span>
                </span>
                <Moment className="text-md" format="YYYY.MM.DD">{data.microcmsPortalNews.createdAt}</Moment>
              </div>
              <div className="text-md py-8" dangerouslySetInnerHTML={{
                __html: `${data.microcmsPortalNews.body}`,
              }} />
            </div>
          </div>

        </div>
      </section>

    </Layout>
  )
}

export default DraftPage